var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position_relative" }, [
    _c("textarea", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.text,
          expression: "text",
        },
      ],
      ref: "textareaTransAddr",
      class: { "upper-textarea": _vm.isUpper },
      attrs: { id: _vm.id, name: _vm.name },
      domProps: { value: _vm.text },
      on: {
        input: [
          function ($event) {
            if ($event.target.composing) return
            _vm.text = $event.target.value
          },
          function ($event) {
            return _vm.onInput($event)
          },
        ],
      },
    }),
    _vm.maxByte > 0
      ? _c("div", { staticClass: "div_req_byte" }, [
          _c(
            "span",
            {
              staticClass: "color_black font_medium",
              attrs: { id: "byte_" + _vm.id },
            },
            [_vm._v("0")]
          ),
          _c("span", { staticClass: "color_lightgray" }, [_vm._v("/")]),
          _c("span", { staticClass: "color_lightgray" }, [_vm._v("2,000")]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }