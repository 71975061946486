<template>
  <div class="position_relative">
    <textarea ref="textareaTransAddr"
              :id="id"
              :name="name"
              v-model="text"
              @input="onInput($event)"
              @keyup="onKeyup($event)"
    ></textarea>
    <div class="div_req_byte" v-if="maxByte > 0">
      <span class="color_black font_medium" :id="'byte_' + id">0</span>
      <span class="color_lightgray">/</span>
      <span class="color_lightgray">2,000</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ETextareaLineColumn',
  props: {
    id: { type: String, required: true },
    isRequired: { type: Boolean, default: false, required: false },
    messageRequired: { type: String, default: '', required: false },
    maxLine: { type: Number, default: 0, required: false },
    columnLength: { type: Number, default: 0, required: false },
    name: { type: String, default: '', required: false },
    value: { type: String, default: '', required: false },
    maxByte: { type: Number, default: 0, required: false },
    isUpper: { type: Boolean, default: false, required: false }
  },
  data () {
    return {
      elemId: '',
      text: '',
      rtnText: '',
      rtnNumber: 0,
      interval: undefined
    }
  },
  watch: {
    value () {
      if (this.text !== this.$props.value) {
        this.text = this.$props.value
        this.check(this.text)
      }
    }
  },
  created () {
    this.text = this.$props.value
  },
  methods: {
    onInput (e) {
      let lineCount = (e.target.value.match(/\n/g) || []).length + 1
      let lines = e.target.value.split('\n')
      if (lineCount > this.maxLine) {
        const elem = document.querySelector('#' + this.id)
        let msg = ''
        msg = this.maxLine + this.$t('js.utillmanager.010')
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        this.text = this.$props.value

        if (lineCount > this.maxLine) {
          lines = lines.slice(0, this.maxLine - lines.length)
          if (lines.length > this.maxLine) {
            lines = lines.splice(0, this.maxLine - lines.length)
          }
          if (e !== undefined) {
            e.target.value = lines.join('\n')
          }
          this.text = lines.join('\n')
          this.$emit('input', lines.join('\n'))
        }

        return false
      }
      let val = this.isUpper ? e.target.value.toUpperCase() : e.target.value
      this.check(val, e)
    },
    check (val, e) {
      // let val = this.isUpper ? e.target.value.toUpperCase() : e.target.value
      const arr = []
      let cnt = 0
      let lineCnt = 0
      let colOverCnt = 0
      let c

      for (let i = 0; i < val.length; i++) {
        c = val.charCodeAt(i)
        if (c >= 0 && c <= 127) {
          arr.push('' + val.charAt(i))
        } else {
          cnt++
        }
      }

      const THIS = this
      const elem = document.querySelector('#' + this.id)

      if (cnt > 0) {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.

        e.target.value = arr.join('')
        this.text = arr.join('')
        this.$emit('input', arr.join(''))

        if (this.interval !== undefined) {
          clearInterval(this.interval)
          this.interval = undefined
        }

        this.interval = setInterval(() => {
          THIS.$ekmtcCommon.hideErrorTooltip(elem)
          clearInterval(this.interval)
          this.interval = undefined
        }, 3000)

        return
      }

      if (val === '') {
        lineCnt = 0
      } else if (val.indexOf('\r\n') > -1) {
        const arr = val.split('\r\n')

        lineCnt = arr.length

        if (this.columnLength > 0) {
          const tempArr = []

          arr.forEach(str => {
            while (str.length > this.columnLength) {
              tempArr.push(str.substring(0, this.columnLength))
              str = str.substring(this.columnLength)
            }
            tempArr.push(str)
          })

          val = tempArr.join('\r\n')
          lineCnt = tempArr.length
        }
      } else if (val.indexOf('\n') > -1) {
        const arr = val.split('\n')

        lineCnt = arr.length

        if (this.columnLength > 0) {
          const tempArr = []

          arr.forEach(str => {
            while (str.length > this.columnLength) {
              tempArr.push(str.substring(0, this.columnLength))
              str = str.substring(this.columnLength)
            }
            tempArr.push(str)
          })

          val = tempArr.join('\n')
          lineCnt = tempArr.length
        }
      } else {
        lineCnt = 1
        // 한 Line 에 columnLength 초과 될경우 줄바꿈 처리
        if (this.columnLength > 0 && val.length > this.columnLength) {
          const tempArr = []
          while (val.length > this.columnLength) {
            tempArr.push(val.substring(0, this.columnLength))
            val = val.substring(this.columnLength)
          }
          tempArr.push(val)
          val = tempArr.join('\n')
          lineCnt = tempArr.length
        }
      }

      let isOk = true
      let msg = ''

      if (this.isRequired && val === '') {
        isOk = false
        msg = this.messageRequired
      } else if (this.maxLine > 0 && lineCnt > this.maxLine) {
        isOk = false
        // msg = this.$t('js.utillmanager.010', { maxLine: this.maxLine })
        msg = this.maxLine + this.$t('js.utillmanager.010')
      } else if (this.maxByte > 0 && !this.$ekmtcCommon.checkTextByte(val, this.maxByte)) {
        isOk = false
        msg = 'Too large text to create S/R.'
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      if (e !== undefined) {
        e.target.value = val
      }
      this.text = val
      this.$emit('input', val)
    },
    onKeyup (e) {
      let lineCount = (e.target.value.match(/\n/g) || []).length + 1
      let lines = e.target.value.split('\n')
      if (lineCount > this.maxLine) {
        const elem = document.querySelector('#' + this.id)
        let msg = ''
        msg = this.maxLine + this.$t('js.utillmanager.010')
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        this.text = this.$props.value

        if (lineCount > this.maxLine) {
          lines = lines.slice(0, this.maxLine - lines.length)
          if (lines.length > this.maxLine) {
            lines = lines.splice(0, this.maxLine - lines.length)
          }
          if (e !== undefined) {
            e.target.value = lines.join('\n')
          }
          this.text = lines.join('\n')
          this.$emit('input', lines.join('\n'))
        }
      }

      if (this.maxByte === 0) {
        return
      }
      this.$ekmtcCommon.fnKeyupByteCheck(e, this.maxByte)
    }
  }
}
</script>

<style scoped>
  .div_req_byte {text-align:right; margin-top:2px;}
  textarea {
    font-family: 'Courier New';
    /* overflow: hidden; */
    /* width: auto; */
    /* padding-right: calc(100% - 206px); */
    padding-top: 2px;
    padding-bottom: 2px;
    box-sizing: border-box;
    text-align: start;
    writing-mode: horizontal-tb !important;
    /* resize: none; */
    line-height: normal;
  }
</style>
