<template>
  <div class="position_relative">
    <textarea
      ref="textareaTransAddr"
      :id="id"
      :name="name"
      v-model="text"
      @input="onInput($event)"
      :class="{'upper-textarea': isUpper}"
    ></textarea>
    <div class="div_req_byte" v-if="maxByte > 0">
      <span class="color_black font_medium" :id="'byte_' + id">0</span>
      <span class="color_lightgray">/</span>
      <span class="color_lightgray">2,000</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ETextarea',
  props: {
    id: { type: String, required: true },
    isRequired: { type: Boolean, default: false, required: false },
    messageRequired: { type: String, default: '', required: false },
    maxLine: { type: Number, default: 0, required: false },
    columnLength: { type: Number, default: 0, required: false },
    name: { type: String, default: '', required: false },
    value: { type: String, default: '', required: false },
    maxByte: { type: Number, default: 0, required: false },
    isUpper: { type: Boolean, default: false, required: false }
  },
  data () {
    return {
      elemId: '',
      text: '',
      rtnText: '',
      rtnNumber: 0,
      interval: undefined,
      prevSelectionPosition: 0
    }
  },
  created () {
    this.text = this.$props.value
  },
  methods: {
    onInput (e) {
      let s = e.target.value
      this.prevSelectionPosition = this.$refs.textareaTransAddr.selectionStart

      const koreanExp = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g
      const isKor = koreanExp.test(s)
      s = s.replace(koreanExp, '')

      e.target.value = s
      this.text = s

      console.log('@@@@ isKor: ', isKor)
      const elem = document.querySelector('#' + this.id)
      if (isKor) {
        const THIS = this

        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.

        if (this.interval !== undefined) {
          clearInterval(this.interval)
          this.interval = undefined
        }

        this.interval = setInterval(() => {
          THIS.$ekmtcCommon.hideErrorTooltip(elem)
          clearInterval(this.interval)
          this.interval = undefined
        }, 3000)

        return
      }

      let lineCount = (e.target.value.match(/\n/g) || []).length + 1
      let lines = e.target.value.split('\n')
        console.log('@@@@ lineCount: ', lineCount)
      console.log('@@@@ this.maxLine: ', this.maxLine)
      if (lineCount > this.maxLine) {
        const elem = document.querySelector('#' + this.id)
        let msg = ''
        msg = this.maxLine + this.$t('js.utillmanager.010')
        this.$ekmtcCommon.showErrorTooltip(elem, msg)

        if (lineCount > this.maxLine) {
          lines = lines.slice(0, this.maxLine - lines.length)
          if (lines.length > this.maxLine) {
            lines = lines.splice(0, this.maxLine - lines.length)
          }

          if (e !== undefined) {
            e.target.value = lines.join('\n')
          }
          this.text = lines.join('\n')

          if (lineCnt !== 1 && this.prevSelectionPosition !== this.$refs.textareaTransAddr.selectionStart) {
            this.$refs.textareaTransAddr.selectionStart = this.prevSelectionPosition + 1
            this.$refs.textareaTransAddr.selectionEnd = this.prevSelectionPosition + 1
          }
        }

        return
      }

      let val = e.target.value
      console.log('@@@@ val: ', val)
      this.check(val, e)
    },
    check (val, e) {
      const arr = []
      let cnt = 0
      let lineCnt = 0
      let c

      const elem = document.querySelector('#' + this.id)

      if (val === '') {
        lineCnt = 0
      } else if (val.indexOf('\r\n') > -1) {
        const arr = val.split('\r\n')
        console.log('@@@@ arr: ', arr)

        lineCnt = arr.length

        if (this.columnLength > 0) {
          const tempArr = []

          arr.forEach((str) => {
            while (str.length > this.columnLength) {
              tempArr.push(str.substring(0, this.columnLength))
              str = str.substring(this.columnLength)
            }
            tempArr.push(str)
          })

          val = tempArr.join('\r\n')
          lineCnt = tempArr.length
        }
      } else if (val.indexOf('\n') > -1) {
        const arr = val.split('\n')
        console.log('@@@@ arr: ', arr)
        console.log('@@@@ ', this.$refs.textareaTransAddr)
        console.log(this.$refs.textareaTransAddr.selectionStart)

        console.log(this.$refs.textareaTransAddr.selectionEnd)

        lineCnt = arr.length

        if (this.columnLength > 0) {
          const tempArr = []

          // arr.forEach((str, idx) => {
          //   let _str = str
          //   console.log('@@@@ str.length: ', str.length)
          //   while (str.length > this.columnLength) {
          //     tempArr.push(str.substring(0, this.columnLength))

          //     str = str.substring(this.columnLength)
          //     if (arr.length > idx + 1) {
          //       str += arr[idx + 1]
          //     }
          //   }
          //   console.log('@@@@ str: ', str)
          //   console.log('@@@@ _str: ', _str)
          //   // tempArr.push(_str + arr[idx + 1])
          //   tempArr.push(str)
          //   // tempArr.push(arr[idx + 1] + str)
          // })

          arr.forEach((str) => {
            while (str.length > this.columnLength) {
              tempArr.push(str.substring(0, this.columnLength))
              str = str.substring(this.columnLength)
            }
            tempArr.push(str)
          })
          console.log('@@@@ tempArr: ', tempArr)

          val = tempArr.join('\n')
          lineCnt = tempArr.length
        }
      } else {
        lineCnt = 1
        // 한 Line 에 columnLength 초과 될경우 줄바꿈 처리
        if (this.columnLength > 0 && val.length > this.columnLength) {
          const tempArr = []
          while (val.length > this.columnLength) {
            tempArr.push(val.substring(0, this.columnLength))
            val = val.substring(this.columnLength)
          }
          tempArr.push(val)
          val = tempArr.join('\n')
          lineCnt = tempArr.length
        }
      }

      let isOk = true
      let msg = ''

      if (this.isRequired && val === '') {
        isOk = false
        msg = this.messageRequired
      } else if (this.maxLine > 0 && lineCnt > this.maxLine) {
        isOk = false
        // msg = this.$t('js.utillmanager.010', { maxLine: this.maxLine })
        msg = this.maxLine + this.$t('js.utillmanager.010')
      } else if (
        this.maxByte > 0 &&
        !this.$ekmtcCommon.checkTextByte(val, this.maxByte)
      ) {
        isOk = false
        msg = 'Too large text to create S/R.'
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }

      this.prevSelectionPosition = this.$refs.textareaTransAddr.selectionStart

      if (e !== undefined) {
        e.target.value = val
      }
      this.text = val

      if (lineCnt !== 1 && this.prevSelectionPosition !== this.$refs.textareaTransAddr.selectionStart) {
        this.$refs.textareaTransAddr.selectionStart = this.prevSelectionPosition + 1
        this.$refs.textareaTransAddr.selectionEnd = this.prevSelectionPosition + 1
      }
    },
    onKeyup (e) {
      let lineCount = (e.target.value.match(/\n/g) || []).length + 1
      let lines = e.target.value.split('\n')
      if (lineCount > this.maxLine) {
        const elem = document.querySelector('#' + this.id)
        let msg = ''
        msg = this.maxLine + this.$t('js.utillmanager.010')
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
        // console.log('@@@@ lineCount: ', lineCount)
        // console.log('@@@@ this.maxLine: ', this.maxLine)
        // this.text = this.$props.value

        if (lineCount > this.maxLine) {
          lines = lines.slice(0, this.maxLine - lines.length)
          if (lines.length > this.maxLine) {
            lines = lines.splice(0, this.maxLine - lines.length)
          }
          if (e !== undefined) {
            e.target.value = lines.join('\n')
          }
          this.text = lines.join('\n')

          if (lineCnt !== 1 && this.prevSelectionPosition !== this.$refs.textareaTransAddr.selectionStart) {
            this.$refs.textareaTransAddr.selectionStart = this.prevSelectionPosition + 1
            this.$refs.textareaTransAddr.selectionEnd = this.prevSelectionPosition + 1
          }
        }
      }

      if (this.maxByte === 0) {
        return
      }
      this.$ekmtcCommon.fnKeyupByteCheck(e, this.maxByte)
    }
  }
}
</script>

<style scoped>
.div_req_byte {
  text-align: right;
  margin-top: 2px;
}

.upper-textarea {
  text-transform: uppercase;
}
</style>
